import { translator } from '../services/translator';

// Use Bootstrap for the Web
export const CONTENT_EDITOR_WEB_OPTIONS = {
    snippetOpen: false,
    snippetHandle: false,
    elementSelection: false,
    toolbarDisplay: 'always',
    toolbar: 'right',
    buttons: [
        'bold',
        'italic',
        'underline',
        'formatting',
        'formatPara',
        'color',
        'align',
        'createLink',
        'image',
        'list',
        '|',
        'undo',
        'redo',
        '|',
        'more',
    ],
    buttonsMore: ['font', 'html', 'preferences'],
    plugins: ['symbols'],
    row: 'row',
    cols: [
        'col-md-1',
        'col-md-2',
        'col-md-3',
        'col-md-4',
        'col-md-5',
        'col-md-6',
        'col-md-7',
        'col-md-8',
        'col-md-9',
        'col-md-10',
        'col-md-11',
        'col-md-12',
    ],
    lang: translator.trans('contentbuilder'),
    scriptPath: '/contentbuilder/contentbuilder/',
    assetPath: '/contentbuilder/assets/',
    modulePath: '/contentbuilder/assets/modules/',
    fontAssetPath: '/contentbuilder/assets/fonts/',
    snippetData: '/contentbuilder/assets/minimalist-blocks/snippetlist.html',
    snippetCategories: [],
};

// Use Foundation for emails
export const CONTENT_EDITOR_EMAIL_OPTIONS = {
    emailMode: true,
    absolutePath: true,
    snippetOpen: false,
    snippetHandle: false,
    toolbarDisplay: 'always',
    toolbar: 'right',
    buttons: [
        'bold',
        'italic',
        'underline',
        'formatting',
        'formatPara',
        'color',
        'align',
        'createLink',
        'image',
        'list',
        '|',
        'undo',
        'redo',
        '|',
        'more',
    ],
    buttonsMore: ['font', 'html', 'preferences'],
    plugins: ['symbols'],
    snippetData: '/contentbuilder/assets/email-blocks/snippetlist.html',
    rowFormat:
        '<div><table align="center" class="container float-center"><tbody><tr><td><table class="row"><tbody><tr>' +
        '</tr></tbody></table></td></tr></tbody></table></div>',
    cellFormat:
        '<th class="small-12 large-12 columns first last"><table><tbody><tr><th>' + '</th></tr></tbody></table></th>',
    lang: translator.trans('contentbuilder'),
    scriptPath: '/contentbuilder/contentbuilder/',
    assetPath: '/contentbuilder/assets/',
    modulePath: '/contentbuilder/assets/modules/',
    fontAssetPath: '/contentbuilder/assets/fonts/',
    defaultEmailSnippetCategory: '1',
    emailSnippetCategories: [],
};
